<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from "axios";
import Multiselect from "vue-multiselect";
import { bankTypeEnum } from "@/components/enums.js";

let user = JSON.parse(localStorage.getItem("user"));
let token = user.token;

export default {
  page: {
    title: "Banka & Kasa Güncelleme",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      title: "Banka & Kasa Güncelleme",
      items: [
        {
          text: "RTS",
          href: "/",
        },
        {
          text: "Banka & Kasa Listesi",
          href: "/banks",
        },
        {
          text: "Banka & Kasa Güncelleme",
          active: true,
        },
      ],
      isAlertVisibleSuccess: false,
      isAlertVisibleDanger: false,
      values: {
        bank: "",
        currency: 0,
        type: 0,
        acccount_holder: "",
        balance: 0,
      },
      currency: [],
      bankTypeEnum,
      tableData: [],
      totalRows: 1,
      currentPage: 1,
      banks: [],
      fields: [
        { key: "id", sortable: true, label: "İşlem Numarası" },
        { key: "date", sortable: true, label: "İşlem Tarihi" },
        { key: "type", sortable: true, label: "İşlem Türü" }, // currency
        { key: "comment", sortable: true, label: "İşlem Açıklaması" },
        { key: "total", sortable: true, label: "İşlem Tutarı" },
        { key: "nextTotal", sortable: true, label: "İşlem Sonrası Bakiye" },
        { key: "actions", label: "İşlemler" },
      ],
      historyType: [
        { id: 1, text: "Giriş" },
        { id: 2, text: "Çıkış" }
      ],
      history: {
        date: "",
        type: "",
        comment: "",
        balance: ""
      },
      transfer: {
        date: "",
        comment: "",
        send_balance: 0,
        receiver_balance: 0,
        receiver: 0,
        send: 0,
      },
      balance: 0,
      test: [],
    };
  },
  mounted() {
    this.getData(), this.getAllCurrency(), this.getWhere(), this.getAllBanks();
  },
  methods: {
    getData() {
      const itemId = this.$route.params.id;
      const api_url = process.env.VUE_APP_BASEURL + `/banks/${itemId}`;

      axios
        .get(api_url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.values = response.data?.data;
          this.balance = response.data?.data.balance;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getWhere() {
      const itemId = this.$route.params.id;
      const api_url = process.env.VUE_APP_BASEURL + "/banksHistory/getWhere/" + itemId + "?page=" + this.currentPage;

      axios
        .get(api_url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.tableData = response.data?.data;
          this.totalRows = response.data?.data.length;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getAllCurrency() {
      const api_url = process.env.VUE_APP_BASEURL + "/exchange/getAll";
      axios
        .get(api_url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.currency = response.data?.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getAllBanks() {
      const api_url = process.env.VUE_APP_BASEURL + "/banks/getAll";
      axios
        .get(api_url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.banks = response.data?.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    update() {
      const itemId = this.$route.params.id;
      const api_url = process.env.VUE_APP_BASEURL + `/banks/${itemId}`;
      const data = {
        bank: this.values.bank,
        currency: this.values.currency.id,
        type: this.values.type.id,
        acccount_holder: this.values.acccount_holder,
        balance: this.values.balance,
      }
      axios
        .put(api_url, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          this.showAlertSuccess();
        })
        .catch(() => {
          this.showAlertDanger();
        });
    },
    addHistory() {
      const itemId = this.$route.params.id;
      const api_url = process.env.VUE_APP_BASEURL + `/banksHistory`;
      const data = {
        relatedId: itemId,
        date: this.history.date,
        type: this.history.type.id,
        comment: this.history.comment,
        total: this.history.balance,
        nextTotal: this.history.type.id == 1 ? (parseFloat(this.balance) + parseFloat(this.history.balance)) : (parseFloat(this.balance) - parseFloat(this.history.balance))
      }
      axios
        .post(api_url, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          this.showAlertSuccess();
          this.getData();
          this.getWhere();
          this.$refs.myModal.hide()
        })
        .catch(() => {
          this.showAlertDanger();
        });
    },
    addTransfer() {
      const itemId = this.$route.params.id;
      const api_url = process.env.VUE_APP_BASEURL + `/banks/transfer`;
      const data = {
        date: this.transfer.date,
        comment: this.transfer.comment,
        send_balance: this.transfer.send_balance,
        receiver_balance: this.transfer.receiver_balance,
        send: parseInt(itemId, 10),
        receiver: this.transfer.receiver.id
      }
      axios
        .post(api_url, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          this.showAlertSuccess();
          this.getData();
          this.getWhere();
          this.$refs.myModalTransfer.hide()
        })
        .catch(() => {
          this.showAlertDanger();
        });
    },
    showAlertSuccess() {
      this.isAlertVisibleSuccess = true
    },
    showAlertDanger() {
      this.isAlertVisibleDanger = true
    },
    updateItem() {
      const itemId = this.$route.params.id;
      const api_url = process.env.VUE_APP_BASEURL + `/city/${itemId}`;

      axios
        .put(api_url, this.values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          this.showAlertSuccess();
          this.getData();
          this.getWhere();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    onPageChange(newPage) {
      this.currentPage = newPage;
      this.getWhere();
    },
    deleteItem(id) {
      const api_url = process.env.VUE_APP_BASEURL + `/banksHistory/${id}`;
      axios
        .delete(api_url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response) {
            this.showAlertSuccess();
            this.getData();
            this.getWhere();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row justify-content-center">
      <div class="col-12 col-md-12">
        <b-alert v-if="isAlertVisibleSuccess" show variant="success">Başarıyla güncellendi.</b-alert>
        <b-alert v-if="isAlertVisibleDanger" show variant="danger">Hata! Lütfen daha sonra tekrar deneyiniz.</b-alert>
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="update">
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group mb-3">
                    <label for="bank">Banka & Kasa</label>
                    <input v-model="values.bank" type="text" class="form-control" id="bank" />
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group mb-3">
                    <label for="acccount_holder">Hesap Adı</label>
                    <input v-model="values.acccount_holder" type="text" class="form-control" id="acccount_holder" />
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="form-group mb-3">
                    <label for="type">Hesap Türü</label>
                    <multiselect v-model="values.type" :options="bankTypeEnum" label="text" :hover="false">
                    </multiselect>
                  </div>
                </div>
                <div class="col-8 col-md-4">
                  <div class="form-group mb-3">
                    <label for="balance">Bakiye</label>
                    <input v-model="values.balance" type="double" class="form-control" id="balance" />
                  </div>
                </div>

                <div class="col-4 col-md-4">
                  <div class="form-group mb-3">
                    <label for="currency">Para Birimi</label>
                    <multiselect v-model="values.currency" :options="currency" label="name" :hover="false">
                    </multiselect>
                  </div>
                </div>
                <div class="col-12 col-md-12">
                  <div class="d-flex justify-content-end align-items-center">
                    <button type="submit" class="btn btn-primary px-4">Kaydet</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-12">

        <div class="card">
          <div class="card-body">

            <div class="row">
              <div class="col-12 d-flex justify-content-between align-items-center mb-3">
                <h4 class="card-title m-0">İşlem Listesi</h4>
                <b-button v-b-modal.modal-center variant="btn btn-primary">İşlem Ekle</b-button>
                <b-button v-b-modal.modal-transfer variant="btn btn-primary">Transfer Ekle</b-button>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-12">
                <div class="table-responsive mb-0">
                  <b-table :items="tableData" :fields="fields" responsive="sm" :current-page="currentPage">
                    <template #cell(type)="data">
                      <b-badge :variant="data.item.type.variant">
                        {{ data.item.type.text }}
                      </b-badge>
                    </template>
                    <template v-slot:cell(balanceBelongsCurrency)="data">
                      {{ data.item.balance + " " + data.item.currencySymbol }}
                    </template>
                    <template v-slot:cell(actions)="data">
                      <button @click="deleteItem(data.item.id)" class="btn btn-sm btn-danger ml-2">
                        Sil
                      </button>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="totalRows" @change="onPageChange">
                    </b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <b-modal id="modal-center" centered ref="myModal" title="İşlem Ekle" title-class="font-18" size="xl" hide-footer>
      <form @submit.prevent="addHistory">
        <div class="row">
          <div class="col-12 col-md-4">
            <div class="form-group mb-3">
              <label for="bank">Ödeme Tarihi</label>
              <input v-model="history.date" type="date" class="form-control" id="bank" />
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="form-group mb-3">
              <label for="acccount_holder">İşlem Türü</label>
              <multiselect v-model="history.type" :options="historyType" label="text" :hover="false">
              </multiselect>
            </div>
          </div>
          <div class="col-8 col-md-4">
            <div class="form-group mb-3">
              <label for="balance">İşlem Tutarı</label>
              <input v-model="history.balance" type="double" class="form-control" id="balance" />
            </div>
          </div>
          <div class="col-12 col-md-12">
            <div class="form-group mb-3">
              <label for="type">İşlem Açıklaması</label>
              <textarea v-model="history.comment" class="form-control"></textarea>
            </div>
          </div>
          <div class="col-12 col-md-12">
            <div class="d-flex justify-content-end align-items-center">
              <button type="submit" class="btn btn-primary px-4">Kaydet</button>
            </div>
          </div>
        </div>
      </form>
    </b-modal>

    <b-modal id="modal-transfer" centered ref="myModalTransfer" title="Bakiye Transfer" title-class="font-18" size="xl" hide-footer>
      <form @submit.prevent="addTransfer">
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group mb-3">
              <label for="bank">Ödeme Tarihi</label>
              <input v-model="transfer.date" type="date" class="form-control" id="bank" />
            </div>
          </div>
          <div class="col-8 col-md-6">
            <div class="form-group mb-3">
              <label for="balance">Gönderen Tutarı</label>
              <input v-model="transfer.send_balance" type="double" class="form-control" id="balance" />
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group mb-3">
              <label for="acccount_holder">Alıcı</label>
              <multiselect v-model="transfer.receiver" :options="banks" label="label" :hover="false">
              </multiselect>
            </div>
          </div>
          <div class="col-8 col-md-6">
            <div class="form-group mb-3">
              <label for="balance">Alıcı Tutarı</label>
              <input v-model="transfer.receiver_balance" type="double" class="form-control" id="balance" />
            </div>
          </div>
          <div class="col-12 col-md-12">
            <div class="form-group mb-3">
              <label for="type">İşlem Açıklaması</label>
              <textarea v-model="transfer.comment" class="form-control"></textarea>
            </div>
          </div>
          <div class="col-12 col-md-12">
            <div class="d-flex justify-content-end align-items-center">
              <button type="submit" class="btn btn-primary px-4">Kaydet</button>
            </div>
          </div>
        </div>
      </form>
    </b-modal>
  </Layout>
</template>
